import { Injectable } from '@angular/core';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseService {

  urlService(){
    return 'contacts'
  }
}
