import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from 'src/app/app.api';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root'
})
export class NeedsService extends BaseService {

  urlService(){
    return 'needs'
  }

  getNeeds(page:number, option, limit?:number): Observable<any>{
    let filter
    if(!limit) limit = 12
    if(page>=1){ filter = new HttpParams().set('limit', `${limit}`)
                                          .set('page', `${page}`)
                                          .set('order', option.order)}
    return this.http.get(`${API}/${this.urlService()}`, {params: filter})
  }

  getNeedsUser(page:number, limit?:number): Observable<any>{
    let filter
    if(!limit) limit = 12
    if(page>=1){ filter = new HttpParams().set('limit', `${limit}`).set('page', `${page}`)}
    return this.http.get(`${API}/users/list/${this.urlService()}`, {params: filter})
  }

  getStatus(page:number, status: string, limit?:number): Observable<any>{
    let filter
    if(!limit) limit = 12
    if(page>=1){ filter = new HttpParams().set('limit', `${limit}`).set('page', `${page}`).set('status', `${status}`)}
    return this.http.get(`${API}/${this.urlService()}`, {params: filter})
  }

  activeInactiveNeeds(id:string, body): Observable<any>{
    return this.http.patch(`${API}/${this.urlService()}/${id}`, body)
  }

  postComment(id:string, body): Observable<any>{
    return this.http.post(`${API}/${this.urlService()}/${id}/comments`, body)
  }

  postCommentInComment(group_id: string, comment_id:string, body): Observable<any>{
    return this.http.post(`${API}/${this.urlService()}/${group_id}/comments/${comment_id}/responses`, body)
  }

  deleteComment(id:string): Observable<any>{
    return this.http.delete(`${API}/${this.urlService()}/${id}/comments`)
  }

  deleteCommentInComment(group_id: string, comment_id:string, comment_response_id:string): Observable<any>{
    return this.http.delete(`${API}/${this.urlService()}/${group_id}/comments/${comment_id}/responses/${comment_response_id}`)
  }
}
