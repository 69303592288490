import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from 'src/app/app.api';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService extends BaseService {

  urlService(){
    return 'events'
  }

  getEventsUser(page:number, limit?:number): Observable<any>{
    let filter
    if(!limit) limit = 12
    if(page>=1){ filter = new HttpParams().set('limit', `${limit}`).set('page', `${page}`)}
    return this.http.get(`${API}/users/user/list/${this.urlService()}`, {params: filter})
  }

  getStatus(page:number, status: string, limit?:number): Observable<any>{
    let filter
    if(!limit) limit = 12
    if(page>=1){ filter = new HttpParams().set('limit', `${limit}`).set('page', `${page}`).set('status', `${status}`)}
    return this.http.get(`${API}/${this.urlService()}`, {params: filter})
  }

  canceledEvent(event_id:string, status): Observable<any>{
    return this.http.patch(`${API}/${this.urlService()}/${event_id}`, status)
  }

  participatEvent(event_id:string): Observable<any>{
    return this.http.post(`${API}/users/${this.urlService()}/${event_id}`, {})
  }

  exitEvent(user_id:string, event_id:string): Observable<any>{
    return this.http.delete(`${API}/users/${user_id}/${this.urlService()}/${event_id}`)
  }

  activeInactiveEvent(id:string, body): Observable<any>{
    return this.http.patch(`${API}/${this.urlService()}/${id}`, body)
  }

  likeComment(id_group:string, id_comment:string): Observable<any>{
    return this.http.post(`${API}/${this.urlService()}/${id_group}/comments/${id_comment}/likes`, {})
  }

  deslikeComment(id_group:string, id_comment:string): Observable<any>{
    return this.http.delete(`${API}/${this.urlService()}/${id_group}/comments/${id_comment}/likes`)
  }

  postComment(id:string, body): Observable<any>{
    return this.http.post(`${API}/${this.urlService()}/${id}/comments`, body)
  }

  postCommentInComment(event_id: string, comment_id:string, body): Observable<any>{
    return this.http.post(`${API}/${this.urlService()}/${event_id}/comments/${comment_id}/responses`, body)
  }

  deleteComment(id:string): Observable<any>{
    return this.http.delete(`${API}/${this.urlService()}/${id}/comments`)
  }

  deleteCommentInComment(event_id: string, comment_id:string, comment_response_id:string): Observable<any>{
    return this.http.delete(`${API}/${this.urlService()}/${event_id}/comments/${comment_id}/responses/${comment_response_id}`)
  }

}
