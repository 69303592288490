import { Injectable } from '@angular/core';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root'
})
export class PrivacyPoliciesService extends BaseService {

  urlService(){
    return 'privacy-policies'
  }
}
